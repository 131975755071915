<script>
import Flag from "@/components/flag.vue";
import lodash from "lodash";

export default {
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    countryId: {
      type: Number,
      required: true,
    },
    flag: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      country: null,
    };
  },
  watch: {
    countryId() {
      this.geoCountryIdGet();
    },
  },
  methods: {
    geoCountryIdGet() {
      this.country = null;
      if (!lodash.isNil(this.countryId)) {
        this.$store
          .dispatch("geoCountryIdGet", {
            id: this.countryId,
          })
          .then((response) => {
            this.country = response.data.data;
          });
      }
    },
  },
  mounted() {
    this.geoCountryIdGet();
  },
  components: {
    Flag,
  },
};
</script>

<template>
  <label v-if="!lodash.isNil(country)" class="mb-0">
    <template v-if="!flag">
      <Flag
        :iso="country.iso2"
        style="margin-bottom: 2px"
        :width="width"
      ></Flag>
      {{ country.name }}
    </template>
    <template v-else>
      <Flag :iso="country.iso2" :title="country.name" :width="width"></Flag>
    </template>
  </label>
</template>
