<script>
import lodash from "lodash";

export default {
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    iso: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 20,
    },
  },
};
</script>

<template>
  <img
    v-if="!lodash.isEmpty(iso)"
    :src="`https://flagcdn.com/${iso.toLowerCase()}.svg`"
    :width="width"
  />
</template>
