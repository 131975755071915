<script>
import lodash from "lodash";
import Country from "./country.vue";
import Flag from "./flag.vue";

export default {
  name: "ItemRanking",
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalLeft() {
      return this.total - this.item.total;
    },
    icon() {
      switch (this.index) {
        case 0:
          return "las la-trophy";
        case 1:
          return "las la-medal";
        case 2:
          return "las la-award";
        default:
          return "";
      }
    },
  },
  components: { Country, Flag },
};
</script>

<template>
  <div class="contest-card">
    <div class="contest-card__thumb">
      <template v-if="!lodash.isNil(item.account.profile.residenceCountry)">
        <Country
          :country-id="item.account.profile.residenceCountry"
          :width="315"
          :height="178"
          :flag="true"
        />
      </template>
      <template v-else>
        <Flag :iso="`us`" :width="315" :height="178" />
      </template>
      <template v-if="lodash.includes([0, 1, 2], index)">
        <a href="#0" class="action-icon"><i :class="icon"></i></a>
      </template>
      <div class="contest-num">
        <img
          :src="getAvatar(item.account)"
          alt="avatar"
          class="img-circle"
          style="
            border-radius: 150px;
            object-fit: cover !important;
            width: 85px;
            height: 85px;
          "
        />
      </div>
    </div>
    <div class="contest-card__content">
      <div class="left">
        <h5 class="contest-card__name">
          {{ item.account.ucode }}
        </h5>
      </div>
      <div class="right">
        <span class="contest-card__price">
          {{ item.total }}
        </span>
        <p>
          {{ $t("text.tickets") }}
        </p>
      </div>
    </div>
    <div class="contest-card__footer">
      <ul class="contest-card__meta" style="width: 1000%">
        <li style="border: none">
          <i class="las la-ticket-alt"></i>
          <span>
            {{ totalLeft }}
          </span>
          <p>
            {{ $t("text.first-place") }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>