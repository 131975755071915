<script>
import AllRanking from "@/components/ranking";
import Feature from "@/components/feature";

export default {
  name: "Ranking",
  components: {
    AllRanking,
    Feature,
  },
};
</script>

<template>
  <!-- inner-hero-section start -->
  <div class="inner-hero-section style--three">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ul class="page-list">
            <li>
              <router-link :to="{ name: 'home' }" tag="a">
                {{ $t("text.home") }}
              </router-link>
            </li>
            <li class="active">
              {{ $t("text.ranking") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- inner-hero-section end -->

  <!-- contest section start  -->
  <section class="pb-120 mt-minus-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="contest-wrapper">
            <div class="contest-wrapper__header pt-120 pb-120">
              <h2 class="contest-wrapper__title mb-0">
                {{ $t("text.ranking") }}
              </h2>
            </div>
            <div class="contest-wrapper__body">
              <div class="tab-content mt-50" id="myTabContent">
                <AllRanking :level="1000" />
              </div>
            </div>
          </div>
          <!-- contest-wrapper end -->
        </div>
      </div>
    </div>
  </section>
  <!-- contest section end -->

  <Feature />
</template>