<script>
import ItemRanking from "@/components/item_ranking";
import lodash from "lodash";

export default {
  name: "Ranking",
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      items: [],
      limit: 20,
      total: 0,
      loading: false,

      totalTickets: 0,
    };
  },
  watch: {
    page() {
      this.vchallengeRankingLevelGet();
    },
  },
  methods: {
    refresh() {
      this.items = [];
      if (this.page > 1) {
        this.page = 1;
      } else {
        this.vchallengeRankingLevelGet();
      }
    },
    vchallengeRankingLevelGet() {
      this.loading = true;
      const params = {
        skip: (this.page - 1) * this.limit,
        limit: this.limit,
      };

      this.$store
        .dispatch("vchallengeRankingLevelGet", {
          level: this.level,
          params: params,
        })
        .then((response) => {
          this.items = response.data.data.items;
          this.total = response.data.data.total;
          this.loading = false;

          if (!this.lodash.isEmpty(this.items) && this.page == 1) {
            this.totalTickets = this.lodash.head(this.items).total;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.total = 0;
          this.$store.dispatch("error", this.getErrorMessage(error));
        });
    },
  },
  created() {
    this.vchallengeRankingLevelGet();
  },
  components: {
    ItemRanking,
  },
};
</script>

<template>
  <div class="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
    <div class="row mb-none-30">
      <template v-for="(item, index) in items" :key="`product-${item._id}`">
        <div class="col-xl-4 col-md-6 mb-30">
          <ItemRanking
            :item="item"
            :total="totalTickets"
            :index="index + (page - 1) * limit"
          />
        </div>
      </template>
    </div>
  </div>

  <template v-if="!lodash.isEmpty(items)">
    <pagination
      v-model="page"
      :records="total"
      :per-page="limit"
      :options="{
        hideCount: true,
      }"
    ></pagination>
  </template>

  <template v-if="!loading && lodash.isEmpty(items)">
    <div class="text-center" style="width: 100%">
      <p class="text-center">
        {{ $t("text.there-is-no-data-display") }}
      </p>
      <img src="@/assets/images/empty.png" alt="" width="350" />
    </div>
  </template>
</template>

<style >
.pagination {
  justify-content: center !important;
}
</style>